<template>
  <div class="nav-icon-wrapper">
    <CustomButton
      v-if="isMobileOrTablet && !isHomepage"
      :is-menu-item="true"
      :icon-only="true"
      style-name="tertiary"
      style-color="black"
      class="zero-font tab-mob-only"
      @click-button="emit('search-clicked')"
    >
      <SearchLine />
    </CustomButton>

    <!-- DESKTOP NAV -->
    <CustomButton
      v-for="userItem in mappedUserNav"
      :key="userItem?.title + 'usernavdesk'"
      v-bind="userItem"
      style-name="tertiary"
      style-color="black"
      :class="[
        'secondary-nav-item',
        { hidden: userItem.hideOnDesktop },
        { spacer: userItem.spacer },
        { endspacer: userItem.endspacer },
      ]"
      @click-button="navStore.navClose()"
    >
      <div class="header-item-title">{{ userItem?.title }}</div>
    </CustomButton>
    <LocaleSwitch v-if="!isMobileOrTablet && locales?.length > 1" />
    <!-- DESKTOP NAV -->

    <!-- MOBILE NAV -->
    <CustomButton
      v-once
      :to="localePath('/account')"
      :is-menu-item="true"
      style-name="tertiary"
      style-color="black"
      aria-label="Account Page"
      class="tab-mob-only-flex"
    >
      <AccountCircleLine />
    </CustomButton>
    <CustomButton
      v-once
      :is-menu-item="true"
      :icon-only="true"
      style-name="tertiary"
      style-color="black"
      aria-label="Show Cart Modal"
      class="tab-mob-only-flex"
      @click-button="openCartModal()"
    >
      <div class="cart-header-container flex">
        <ShoppingCartLine />
        <div v-if="!!numItemsCart" class="num-items">
          {{ numItemsCart }}
        </div>
      </div>
    </CustomButton>
    <!-- MOBILE NAV -->
  </div>
</template>

<script setup lang="ts">
import { useCartStore } from '~/store/cart';
import { useLocaleStore } from '~/store/locale';
import { useDeviceStore } from '~/store/device';
import { useNavStore } from '~/store/nav';
import { mapMenuItem } from '~/util/contentstack/csHelpers';
import { Link } from '~/types/link-menu';

const emit = defineEmits<{ (e: 'search-clicked'): void }>();

const route = useRoute();
const localePath = useLocalePath();
const deviceStore = useDeviceStore();
const navStore = useNavStore();
const { locales } = useI18n();

const numItemsCart = computed(() => useCartStore().numItemsCart);
const isMobileOrTablet = computed(() => deviceStore.isMobileOrTablet);
const langCode = computed(() => useLocaleStore().langCode);
const path = computed(() => route.path?.toLowerCase());
const isHomepage = computed(() => path.value === '/' + langCode.value || path.value === '/' + langCode.value + '/');

const mappedUserNav = navStore?.headerMobileUserNav?.map((item: Link, i: number) => {
  const menuItem = mapMenuItem(item);
  return {
    ...menuItem,
    ...((menuItem?.title?.toLowerCase() === 'cart' || menuItem?.title?.toLowerCase() === 'account') && {
      hideOnDesktop: true,
    }),
    spacer: i > 2, // spacer after 3rd item
    endspacer: i === navStore?.headerMobileUserNav?.length - 1 && locales?.value?.length > 1, // spacer after last item if locale switcher is present
  };
});

const openCartModal = () => useCartStore().setShowCartModal(true);
</script>

<style lang="scss" scoped>
.header-item-title {
  font-weight: normal;
  text-transform: capitalize;
  font-size: 0.875rem;
  line-height: 1.4;
  white-space: nowrap;
  @include local-mixins.desktop {
    font-size: 0.8125rem;
  }
}
.secondary-nav-item {
  display: flex;
  @include local-mixins.tablet_and_mobile {
    display: none;
  }
  @include local-mixins.desktop {
    &.spacer {
      &::before {
        content: ' | ';
        color: $color-neutral-cool-200;
        font-weight: normal;
        margin: 0 6px;
      }
    }
    &.endspacer {
      &::after {
        content: ' | ';
        color: $color-neutral-cool-200;
        font-weight: normal;
        margin: 0 6px;
      }
    }
  }
  &:hover,
  &:focus {
    color: $color-primary-600;
  }
  &:focus {
    background: none;
    box-shadow: none;
  }
  &.hidden {
    display: none;
  }
}
</style>
