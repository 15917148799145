<template>
  <svg xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#a)">
      <path
        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10Zm-2.29-2.333A17.9 17.9 0 0 1 8.027 13H4.062a8.008 8.008 0 0 0 5.648 6.667ZM10.03 13c.151 2.439.848 4.73 1.97 6.752A15.906 15.906 0 0 0 13.97 13h-3.94Zm9.908 0h-3.965a17.9 17.9 0 0 1-1.683 6.667A8.008 8.008 0 0 0 19.938 13ZM4.062 11h3.965A17.9 17.9 0 0 1 9.71 4.333 8.008 8.008 0 0 0 4.062 11Zm5.969 0h3.938A15.904 15.904 0 0 0 12 4.248 15.906 15.906 0 0 0 10.03 11h.001Zm4.259-6.667A17.9 17.9 0 0 1 15.973 11h3.965a8.008 8.008 0 0 0-5.648-6.667Z"
      />
    </g>
    <defs>
      <clipPath id="a"><path d="M0 0h24v24H0z" /></clipPath>
    </defs>
  </svg>
</template>
