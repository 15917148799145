<template>
  <div class="locale-switch">
    <button
      :class="['nav-button flex-inline pointer', { open: navStore.localeModalOpen }]"
      aria-label="Change Locale or Language"
      @click="toggleShow()"
    >
      <img
        v-if="matchedLocale?.flagUrl"
        :src="matchedLocale?.flagUrl"
        :height="flagDimensions.height"
        :width="flagDimensions.width"
        alt="flag"
        class="country-img"
        data-categories="essential"
      />
      <GlobalLine v-else class="global-icon country-img" />
      {{ country }} / {{ lang }}
    </button>
  </div>
</template>

<script setup lang="ts">
import { useDeviceStore } from '~/store/device';
import { useNavStore } from '~/store/nav';
// https://v8.i18n.nuxtjs.org/guide/lang-switcher
const { locale, locales } = useI18n();
const deviceStore = useDeviceStore();

const navStore = useNavStore();
const toggleShow = () => {
  navStore.setLocaleModalOpen(!navStore.localeModalOpen);
};

const isMobileOrTablet = computed(() => deviceStore.isMobileOrTablet);
const flagDimensions = computed(() =>
  isMobileOrTablet.value ? { height: '22px', width: '42px' } : { height: '12px', width: '23px' }
);
const matchedLocale = computed(() => {
  return (locales?.value as any)?.find((i: any) => i.code === locale.value);
});
const country = computed(() => matchedLocale.value?.country);
const lang = computed(() => matchedLocale.value?.lang);
</script>

<style lang="scss" scoped>
.country-img {
  height: 22px;
  width: auto;
  margin-right: 5px;
  @include local-mixins.desktop {
    height: 12px;
    margin-top: 3px;
    margin-bottom: 3px;
  }
}
.locale-switch {
  display: flex;
}
.nav-button {
  font-size: 14px;
  line-height: 18px;
  border: none;
  background-color: transparent;
  padding: 0.4rem 0;
  text-transform: uppercase;
  .global-icon {
    margin-right: 3px;
  }
  .svg-comp-wrapper:not(.global-icon) {
    transition: ease 0.2s;
    transform: rotate(0deg);
    margin-left: 5px;
  }
  &.open {
    .svg-comp-wrapper:not(.global-icon) {
      transform: rotate(-180deg);
    }
  }
  @include local-mixins.tablet_and_mobile {
    padding: 5px 6px;
  }
}
</style>
